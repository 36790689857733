import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout' 
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'

export default class blog extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost

    return (
      <Layout>
        
        <PostList posts={posts} title="Derniers articles" />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    )
  }
}

blog.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  # query BlogQuery($limit: Int!, $skip: Int!) {
  # query BlogQuery($limit: Int!, $skip: Int!) {
  #   allWordpressPost(
  #     sort: { fields: date, order: DESC }
  #     limit: $limit
  #     skip: $skip
  #   ) {
  query BlogQuery{
    allWordpressPost(
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
